import styled from "@emotion/styled"

const SectionTitle = styled.h4`
  margin-bottom: 4rem;
  line-height: 1;
  color: #b6b5b1;
  letter-spacing: 3px;
  text-transform: uppercase;
`

export default SectionTitle
