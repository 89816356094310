import { useMemo } from "react"
import styled from "@emotion/styled"
import { mediaQueries, breakpoints } from "../../styles"
import Section from "../Section"
import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
} from "gatsby-plugin-image"
import Button from "../Button"
import BsContainer from "react-bootstrap/Container"
import { useStaticQuery, graphql } from "gatsby"
import { marked } from "marked"
import insane from "insane"

type Props = {
  title: string
  subtitle?: string
  buttonText: string
  buttonLink: string
  background?: IGatsbyImageData
  backgroundMobile?: IGatsbyImageData
  className?: string
}

interface BannerQuery {
  bannerBg: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
  bannerBgMobile: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
}

const Banner = ({
  title,
  subtitle,
  buttonText,
  buttonLink,
  background: backgroundProp,
  backgroundMobile: backgroundMobileProp,
  ...props
}: Props) => {
  const { bannerBg, bannerBgMobile } = useStaticQuery<BannerQuery>(query)

  const background = useMemo(
    () =>
      !!backgroundProp ? backgroundProp : bannerBg.childImageSharp.gatsbyImageData,
    [bannerBg, backgroundProp],
  )
  const backgroundMobile = useMemo(
    () =>
      !!backgroundMobileProp
        ? backgroundMobileProp
        : bannerBgMobile.childImageSharp.gatsbyImageData,
    [backgroundMobileProp, bannerBgMobile],
  )

  const images = withArtDirection(backgroundMobile, [
    {
      media: `(min-width: ${breakpoints.md}px)`,
      image: background,
    },
  ])

  return (
    <BannerSection {...props}>
      <Wrapper>
        <Image image={images} alt="Banner" />
        <Container>
          <Content>
            <h2
              dangerouslySetInnerHTML={{
                __html: marked.parseInline(insane(title), {
                  breaks: true,
                }),
              }}
            />
            {subtitle && <h6>{subtitle}</h6>}

            <a href={buttonLink} style={{ textDecoration: `none` }}>
              <Button variant="secondary">{buttonText}</Button>
            </a>
          </Content>
        </Container>
      </Wrapper>
    </BannerSection>
  )
}

export default Banner

const query = graphql`
  {
    bannerBg: file(relativePath: { eq: "banner-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    bannerBgMobile: file(relativePath: { eq: "banner-bg-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const BannerSection = styled(Section)`
  margin-bottom: 4rem;
  padding-top: 0;
  padding-bottom: 0;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-auto-rows: max-content;
  position: relative;
  padding: 0;
  align-items: center;
`

const Image = styled(GatsbyImage)`
  position: absolute !important;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const Container = styled(BsContainer)`
  grid-row: 1 / span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 88px 0;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  ${mediaQueries.max576} {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;

    ${mediaQueries.max576} {
      flex: 1 0 100%;
      margin-bottom: 1.75rem;
      font-size: 1.5rem;
    }
  }

  h6 {
    flex: 1 0 100%;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-transform: uppercase;

    ${mediaQueries.min992} {
      display: none;
    }
  }
`
